/*
* 代理商管理
*/
.agent-detail {
  .pro-detail-smailtitle {
    margin-bottom: 0;
    padding-top: 10px;
  }
  .line-box {
    height: 10px;
    background: #f0f2f5;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .line {
    height: 1px;
    background: #f0f2f5;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .pro-detail-title {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .pro-detail-smailtitle
   > .ant-descriptions-title {
    font-size: 14px;
    height: 34px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .promotionProcess {
    padding: 10px 10px;
    box-sizing: border-box;
    .ant-steps-item-description {
      line-height: 30px;
    }
  }
  .pro-apply {
    .ant-descriptions-view {
      .ant-descriptions-item-content {
        color: #AEAEAEFF;
      }
    }
    .change-text {
      color: red;
    }
  }
}

.edit .ant-input{
  color:#B2B2B2
}

.edit .editing.ant-input{
  color: rgba(0,0,0,.65)
}

.has-err .ant-form-item-control-wrapper{
  border: #f5222d solid 1px ;
}

.daokuan-table .ant-table-thead .ant-table-selection-column .ant-table-header-column{display: none}
.zhuti-row {
  padding-top:10px;
  .ant-descriptions-item-colon::after{content:none}
  .ant-descriptions-row > th, .ant-descriptions-row > td{padding-bottom: 0}
  .ant-descriptions{margin-bottom: 10px}
  .zhuti-title{height: 30px; line-height:30px;padding:5px 10px}
  .ant-descriptions-item-label{color:#999}
  .ant-descriptions-item-content{font-size:20px;font-weight:bold}
}

.pro-detail .zhuti-row:first-of-type{padding-top: 30px}

.lianxiren-title .title{display: inline}
.lianxiren-title .add-customerBtn{float:right}
.dailishang-detail .add-customer,.dailishang-detail form.ant-advanced-search-form{margin-top:0}
.dailishang-detail .ant-advanced-search-form .ant-form-item{margin-bottom: 0}
.pro-detail.dailishang-detail .pro-detail-smailtitle > .ant-descriptions-title{height: 38px}


.dailishang-client .kehu-title .ant-descriptions-title .title{ font-weight: normal;color:#999;}
.dailishang-client .kehu-title .ant-descriptions-title .title span{ font-weight: bold; color:rgba(0, 0, 0, 0.65); font-size: 16px;}
.dailishang-client .kehu-title.pro-detail-smailtitle{margin-bottom: 30px}
.agent-ziji{
  .search-buttons{
    button{
      margin-right:15px;
    }
  }
  .cashtype-green{color:#13c2c2}
  .cashtype-red{color:#ff7875}
  .zijin-banner{
    background-color: #e6f7ff;
    border:1px solid #91d5ff;
    line-height: 50px;
    margin-top:20px;
    margin-bottom: 20px;
    .ant-col{
      padding-left:30px;
    }
    .zijin-banner-shouru{
      .zijin-banner-money{color:#13c2c2}
    }
    .zijin-banner-zhichu{
      .zijin-banner-money{color:#ff7875}
    }
    .zijin-banner-money{font-size:20px;font-weight: bold}
  }

}

.agent-zhekou{
  .search-btns{
    margin-bottom: 10px;
  }
}

.lianxirenListBox{
  position: relative;
  overflow: hidden;
  height: 210px;
  .shrink{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 999;
    width: 100%;
    text-align: center;
    color: #1890ff;
    font-weight: 500;
    background: #fff;
    padding: 10px 0 14px;
  }
}